<template>
  <div class="pricing-table">
    <div
      v-for="plan in plans"
      :key="plan.id"
      :class="['pricing-plan', plan.highlight ? 'is-success' : '', plan.id == 3 ? 'is-link' : '']"
    >
      <div class="plan-header">{{ plan.name }}</div>
      <div class="plan-price">
        <span class="plan-price-amount"
          ><span class="plan-price-currency">{{ plan.currency }}</span
          >{{ plan.price }}</span
        >/month
      </div>
      <div class="plan-items">
        <div v-for="(item, index) in plan.items" :key="index" class="plan-item">{{ item }}</div>
      </div>
      <div class="plan-footer">
        <div v-if="plan.free" class="plan-free">No signup required</div>
        <div v-else class="plan-btn">
          <!--  
          <button class="button is-fullwidth" :disabled="plan.currentPlan">Current plan</button> -->
          <button class="button is-fullwidth" @click="email(plan.name)">Get started</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var top = [
  "Unlimited codes",
  "Unlimited traffic",
  "View & edit your codes",
  "Advanced stats",
  "Custom domain",
  "Priority support",
];
export default {
  methods: {
    email(plan) {
      window.location.href = `mailto:team@retarget.cc?subject=Upgrading to ${plan} plan on retarget.cc&body=Hi! \nI'm interested in upgrading to the ${plan} plan on retarget.cc.`;
      // window.location.href = "mailto:
    },
  },
  data() {
    return {
      plans: [
        {
          id: 1,
          name: "Basic",
          price: 0,
          currency: "$",
          items: ["Unlimited codes", "Unlimited traffic", "-", "-", "-", "-"],
          currentPlan: true,
          highlight: false,
          free: true,
        },
        {
          id: 2,
          name: "Startups",
          price: 9,
          currency: "$",
          items: ["Unlimited codes", "Unlimited traffic", "View & edit your codes", "Simple stats", "-", "-"],
          currentPlan: false,
          highlight: true,
        },
        {
          id: 3,
          name: "Enterprise",
          price: 99,
          currency: "$",
          items: top, //["100GB Storage", "Unlimited Domains", "24/7 Support", "Dedicated Account Manager"],
          currentPlan: false,
          highlight: false,
        },
      ],
    };
  },
};
</script>
