<template>
  <div class="homePublic" style="margin-top: -120px">
    <section class="hero is-medium is-main is-darkNO">
      <div class="hero-body" style="padding: 0; margin: 0; padding-top: 60px; padding-bottom: 4rem">
        <div class="container" style="padding: 20px">
          <br />
          <br />

          <!-- 



🤑💸 Stop wasting your marketing budget on the wrong audience. Use QR code retargeting to target the right people and watch your ROI soar!
🚫❌ Don't let your marketing efforts go to waste! Try QR code retargeting and make sure you're reaching the right people.
🤔🤷‍♂️ Not sure if you're reaching the right audience with your marketing campaigns? Use QR code retargeting and be confident that you are!
💰🔥 Why burn through your marketing budget without results? Try QR code retargeting and get the most out of your spend.
🎯🎉 Reach the right people with your marketing efforts and celebrate your success! Use QR code retargeting and get the results you want.



        -->
          <p class="title is-3">
            Stop wasting your marketing budget on the wrong audience 💩

            <!--  💰🔥
              Why burn through your marketing budget without results? 💰🔥
               Don't waste your ad budget on the wrong audience.
          Reaching out to all your customers is easy
         -->
          </p>
          <p class="subtitle is-3">Advertise to people who use your QR code 🎉</p>
          <qrform />
        </div>
      </div>
    </section>
    <br />
    <br />
    <div class="section">
      <div class="container c1200">
        <p class="title is-3">Reach customers, grow business 📈</p>
        <p class="subtitle is-5">No need to be a marketing pro to advertise to valuable audiences</p>

        <div class="columns">
          <div class="column" v-for="feature in keyFeatures" :key="feature.title">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">
                  {{ feature.title }}
                </p>
              </header>
              <div class="card-content">
                <div class="content" v-html="feature.description"></div>
              </div>
            </div>
          </div>
        </div>

        <!-- eo col-->
      </div>
    </div>

    <div class="section">
      <div class="container c1200 promoMenu" style="">
        <div style="text-align: center">
          <h2 class="title is-3">❌ Don't let your marketing efforts go to waste! 🚫</h2>
          <h2 class="subtitle is-4">Fix your QR codes in 10 minutes</h2>
          <br />
        </div>
        <promoMenu />
      </div>
      <hr />
    </div>

    <div class="section pricing" id="pricing">
      <div style="text-align: center">
        <h2 class="title is-3">Pro plans packed with value</h2>
        <h2 class="subtitle is-4">Free forever plan let you create unlimited tracking codes.</h2>
        <br />
      </div>
      <div class="container c1200 pricing">
        <pricing />
      </div>
    </div>

    <div class="section">
      <div class="container c1200 guide">
        <guide />
      </div>
    </div>

    <div class="section" id=" ">
      <div class="container footer" style="text-align: left">
        <p class=" ">© retarget.cc {{ new Date().getFullYear() }} ⚡</p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
//import projCard from "@/components/projCard.vue";
//import waitlist from "@/components/waitlist.vue";
import qrform from "@/components/re/qrform.vue";

import guide from "@/components/re/guide.vue";
import promoMenu from "@/components/re/promoMenu.vue";

//import pricing
import pricing from "@/components/pricing.vue";
const keyFeatures = [
  {
    title: "Get Personal",
    description: " Keep your customers coming back for more by sending them personalized promotions.",
  },
  {
    title: "Expand Your Reach",
    description:
      "Unlock new audiences by creating <strong>lookalike</strong> profiles and getting your message in front of people who are most likely to love your business.",
  },
  {
    title: "Stay Ahead of the Game",
    description:
      "It's a cheap and easy tool. Your competitors might already using QR code retargeting. Reduce your ad-spend and drive more conversion with the right audience. ",
  },
];

/*[
  {
    title: "Speed Up Full-Stack Development",
    description:
      "Midrun centralizes all key information to save you time. Our development tools streamline the process, keeping specifications in sync and simplifying debugging.",
  },
  {
    title: "Effortless API Generation",
    description:
      "Midrun's tools help you write your API specification and generate draft code for all functions with ease. Whether you're an experienced coder or just starting out, you can easily adjust the details to fit your needs.",
  },
  {
    title: "Freedom to Customize",
    description:
      "Our open-source based toolkit gives you the flexibility you need to succeed. Avoid platform and vendor lock-ins and scale with ease, without any hidden fees. Midrun gives you the freedom to build the solution that's right for you.",
  },
];
*/
/*


[
  {
    title: "AI-Optimized API Development",
    description:
      "Midrun leverages AI algorithms to optimize API development, freeing up time and improving efficiency.",
  },
  {
    title: "Predictive Coding",
    description: "Real-time code suggestions and completion to save time and reduce errors.",
  },
  {
    title: "Flexible Toolkit",
    description: "Customizable options to fit your specific needs and build the APIs you need to succeed.",
  },
];*/

export default {
  name: "Home",
  components: {
    qrform, //  waitlist,
    guide,
    promoMenu,
    pricing,
    //  HelloWorld,
    // projCard,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Retarget - QR code retargeting tools for remarketing", //"ApiBros 😎",
    // all titles will be injected into this template
    // titleTemplate: "%s  🍔  ",
    //meta: [{ charset: "utf-8" }, { name: "viewport", content: "width=device-width, initial-scale=1" }],
  },
  data() {
    return {
      keyFeatures,
      projs: [],
      loadingProj: true,
      // loadingOps: true,
    };
  },
  mounted() {
    /*
    window.API.getPublicProjects().then((proj) => {
      this.projs = proj;
      this.loadingProj = false;
      // console.log("PROJJ!", proj, proj.id);
    });*/
  },
};
</script>
<style scoped>
.container.c1200 {
  max-width: 1100px !important ;
}
.hero.is-main {
  background-color: #167df0;
  color: #fff;
  background: linear-gradient(150deg, #ffc92f, #ff185c, #f316d5, #b81ed2);

  background: linear-gradient(139deg, #42ffe5, #55ff2b, #e4ff00);
  background: linear-gradient(139deg, #ffffff, #fbff00, #d6ff00);
}
</style>
