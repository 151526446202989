<template>
  <div>
    <div class="columns">
      <div class="column">
        <form @submit.prevent="createQRCode">
          <b-field label="Your URL">
            <b-input v-model="url" type="url" placeholder="Enter your website URL"></b-input>
          </b-field>

          <b-field label="   ">
            <b-switch type="is-dark" v-model="showFb">I advertise on Facebook. </b-switch>
          </b-field>
          <b-field label=" " v-show="showFb" style="max-width: 380px">
            <b-input v-model="pixelID" placeholder="Enter your Facebook Pixel ID. ex: 12345678"></b-input>
          </b-field>

          <b-field label="   ">
            <b-switch type="is-dark" v-model="showGtm">I advertise on Google</b-switch>
          </b-field>
          <b-field label=" " v-show="showGtm" style="max-width: 380px">
            <b-input v-model="gtmID" placeholder="Enter your Google Tag Manager ID. ex: G-499999"></b-input>
          </b-field>
          <b-field label="API Key" v-show="false">
            <b-input v-model="apiKey" placeholder="Enter your API key"></b-input>
          </b-field>
          <!--  
          <b-field label="I advertise on...  ">
            <b-select v-model="trackingMethod">
              <option value="GTM">Google</option>
              <option value="FB">Facebook / Instagram</option>
              <option value="BOTH">Both</option>
            </b-select>
          </b-field> -->

          <b-button native-type="submit" type="  is-dark" style="text-transform: uppercase; letter-spacing: 1px">
            GET QR code
          </b-button>
          <br />
          <em style="color: black; opacity: 0.2">No email required, 100% free</em>
        </form>
      </div>
      <div class="column">
        <!--        xxeddf

        <vue-qrcode value="https://www.1stg.me" />
        <div v-if="qrCodeUrl">
          <img :src="qrCodeUrl" alt="QR Code" />
        </div>
        <vue-qrcode :value="codeData" />
        2222  -->

        <div
          class="box"
          style="
            margin: auto;
            max-width: 200px;
            text-align: center;
            -webkit-backface-visibility: hidden;
            transform: rotate(6deg);
          "
        >
          <qr-code :text="codeData" error-level="M" name="name33" title="bingo"></qr-code>
          <span>
            <span style="margin-top: 10px; display: block; opacity: 0.5; font-size: 12px">
              {{ String(codeData).replace("https://", "") }}
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueQrcode from "vue-qrcode";

export default {
  data() {
    return {
      url: "",
      gtmID: "",
      apiKey: "",
      pixelID: "",
      qrCodeUrl: "https://retarget.cc/",

      showFb: false,
      showGtm: false,
      codeData: "https://retarget.cc/",
    };
  },
  components: {
    // VueQrcode,
  },
  methods: {
    createQRCode() {
      var d = {
        url: this.url,
        gtm: this.gtmID,
        key: this.apiKey,
        fb: this.pixelID,
      };

      const apiUrl = "https://api.retarget.cc/qr";

      window.API.create(d).then((code) => {
        console.log(code);
        //  this.qrCodeUrl = code.url;

        //show buefy toast confirm code is ready to  use
        this.codeData = code.short; // this.url || base;

        this.$buefy.toast.open({
          message: "Your QR code is ready to use 🎉",
          type: "is-dark",
          position: "is-bottom-right",
          duration: 5000,
          queue: false,
        });
        this.$confetti.start();
        //set timer 3 sec and then stop
        setTimeout(() => {
          this.$confetti.stop();
        }, 1000);
      });

      /*
      const requestData = axios
        .put(apiUrl, requestData)
        .then((response) => {
          this.qrCodeUrl = response.data.qrCodeUrl;
        })
        .catch((error) => {
          console.log(error);
        });*/
    },
  },
};
</script>
<style>
.control-label {
  color: black;
}
</style>
